// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artist-js": () => import("./../../../src/pages/artist.js" /* webpackChunkName: "component---src-pages-artist-js" */),
  "component---src-pages-artists-page-js": () => import("./../../../src/pages/artists_page.js" /* webpackChunkName: "component---src-pages-artists-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-projects-page-js": () => import("./../../../src/pages/projects_page.js" /* webpackChunkName: "component---src-pages-projects-page-js" */),
  "component---src-pages-services-page-js": () => import("./../../../src/pages/services_page.js" /* webpackChunkName: "component---src-pages-services-page-js" */)
}

